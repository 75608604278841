import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-02d24024"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-grid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.showDialog,
    "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showDialog) = $event)),
    header: _ctx.$t('supplementation.delete-modal.title'),
    modal: true
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        label: _ctx.$t('common.cancel'),
        class: "p-button p-component p-button-secondary p-button-medium",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showDialog = false))
      }, null, 8, ["label"]),
      _createVNode(_component_Button, {
        label: _ctx.$t('common.delete'),
        class: "p-button p-component p-button-secondary p-button-medium delete-supplement-confirm-button",
        loading: _ctx.waitSubmit,
        onClick: _ctx.onSubmit
      }, null, 8, ["label", "loading", "onClick"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("form", null, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('supplementation.delete-modal.message')), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('supplementation.delete-modal.description')), 1)
        ])
      ])
    ]),
    _: 1
  }, 8, ["visible", "header"]))
}